define("discourse/plugins/slack/discourse/pre-initializers/extend-category-for-slack", ["exports", "ember-addons/ember-computed-decorators", "discourse/models/category"], function (_exports, _emberComputedDecorators, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = {
    name: 'extend-category-for-slack',
    before: 'inject-discourse-objects',
    initialize() {
      var _dec, _obj, _init;
      _category.default.reopen((_dec = (0, _emberComputedDecorators.default)('custom_fields.slack_channel'), (_obj = {
        slack_channel: {
          get(channelField) {
            return channelField;
          },
          set(value) {
            this.set("custom_fields.slack_channel", value);
            return value;
          }
        }
      }, (_applyDecoratedDescriptor(_obj, "slack_channel", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "slack_channel"), _init = _init ? _init.value : undefined, {
        enumerable: true,
        configurable: true,
        writable: true,
        initializer: function () {
          return _init;
        }
      }), _obj)), _obj)));
    }
  };
});